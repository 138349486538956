import React from "react"
import { graphql } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import LazyLoad from "react-lazyload"
import {
  Helmet,
  Banner,
  Container,
  Row,
  Intro,
  Box,
  routesObject
} from "@life-without-barriers/react-components"
import StoryTile from "../../components/foster-care/StoryTile/StoryTile"
import Layout from "../../components/foster-care/Layout"
import { contentfulStorySummaryToStoryTile, Site } from "../../contentTypes"
import { contentTypes as ct } from "@life-without-barriers/gatsby-common"

interface Props {
  location: { pathname: string }
  data: {
    storySummaries: ct.ContentNodes<ct.FosterCareStorySummary>
    openGraphImage: IGatsbyImageData
    site: Site
  }
}

const StoriesPage = ({
  location,
  data: {
    storySummaries,
    openGraphImage,
    site: { siteMetadata }
  }
}: Props) => {
  return (
    <Layout>
      <Helmet
        title={`What's it like to be a carer - Stories from foster care | ${siteMetadata.title}`}
        description="Learn about foster care through first hand experiences. Our carers share their stories to help you understand how fostering can change a child's life."
        image={openGraphImage}
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
      />
      <Banner
        title="Stories from care"
        breadcrumbs={[routesObject.fosterCareHome]}
      />
      <Container>
        <Row>
          <Box className="layout-readable">
            <Intro
              text="A great way to learn more about foster care is through the
              first-hand experience of carers. Through the highs, lows,
              triumphs, hurdles, setbacks and milestones their stories will help
              you understand why fostering 'will be the best thing you ever do
              for yourself and the lives of these children'. (Paul and Chris -
              Foster Carers in South Australia)"
            />
          </Box>
        </Row>
        <Row className="flex-wrap" topMargin="standard">
          {storySummaries.edges
            .map(({ node }) => contentfulStorySummaryToStoryTile(node))
            .sort((a, b) => a.title.localeCompare(b.title))
            .map((props, i) => {
              return (
                <LazyLoad key={i} once>
                  <StoryTile {...props} key={i} />
                </LazyLoad>
              )
            })}
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    storySummaries: allContentfulFosterCareStory {
      edges {
        node {
          title
          bannerImage {
            ...FosterCareRemoteImageAssetQuery
          }
          slug
          subtitle
          description
        }
      }
    }
    openGraphImage: file(relativePath: { regex: "/contactus.jpg/" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, width: 1200)
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

export default StoriesPage
